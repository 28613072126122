import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState, createContext } from "react";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import './App.css';


//Layout and pages
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import Layout from "./components/Layout";


//utlities
import UserContext from "./Utilities/UserContext"; 
import NotFound from "./pages/404";
import Login from "./pages/Login";
import Register from "./pages/Register";




function App() {

 
  const [user, setUser] = useState("Vic Okeke")
  return (
    <UserContext.Provider value={user}>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />} >
      <Route index element={<Home />} />
      <Route path="/about" element={<About/>}/>
      <Route path="/contact" element={<Contact/>}/>
      <Route path="/services" element={<Services/>}/>
      <Route path="/login" element={<Login/>}/>
      <Route path="/register" element={<Register/>}/>
      <Route path="*" element={<NotFound/>}/>
      </Route>

    </Routes>
   
    </BrowserRouter>
    </UserContext.Provider>
  );
}

export default App;
