import React from 'react'

const TopCities = () => {
  return (
    <section className='d-flex justify-content-center align-items-center bg-primary flex-column top-cities-section mt-4'>
        <h2 className='text-white'>Top Cities in Owerri</h2>
        <div className="btn-row d-flex justify-content-between  flex-wrap">

        <div className="btn btn-light m-3">
            Ihiagwa
        </div>
        <div className="btn btn-light m-3">
            Douglas
        </div>
       
        <div className="btn btn-light m-3">
            Mbaise
        </div>
        <div className="btn btn-light m-3">
            Orlu
        </div>
        </div>

        <div className="btn btn-secondary my-3 all-cities-btn">
            See all cities
        </div>
       
    </section>
  )
}

export default TopCities