import React from 'react'
import HeroImg from '../assets/hero-img.png'
import { CiLocationOn } from "react-icons/ci";

const Hero = () => {
  return (
    <section className='hero-section row'>
        <div className='hero-content  d-flex justify-content-center col-lg-6 flex-column col-12'>
            <h1 className='hero-title'><span className='text-primary'>Efficient Logistics</span> Seamless Deliveries</h1>
            <p className='hero-subtitle'>Connecting you to the World, One delivery at a time</p>
            <div className='cta d-flex justify-content-between align-items-center'>
                <span><CiLocationOn/>
                <input className='placeholder-input' name='location' placeholder="What's your address?" />
                </span>
                <button className='btn cta-btn btn-sm'>Your Location</button>
            </div>
        </div>

        <div className='hero-image col-lg-6 col-12'>
            <img src={HeroImg} alt="hero image" className='hero-img' />
        </div>
    </section>
  )
}

export default Hero