import React from 'react'
import DownloadApp from '../sections/DownloadApp'
import servicesImage from '../assets/services-image.png'
import WarehouseImage from '../assets/warehouse-image.png'
import EcommerceImage from '../assets/ecommerce-image.png'
import CounterSection from '../components/CounterSection'
import LowerShippingSection from '../sections/LowerShippingSection'

const Services = () => {
  return (
    <>
    <section className="secondary-hero">
       <h1 className='secondary-hero-title'> Our Full Range of <span className='text-secondary'> Services</span>. </h1>
       <p className='secondary-hero-paragraph'>
       From Start to Finish, We’ve Got You Covered.
       </p>
    </section>

    <section className='bg-light'>
      <div className='container'>
      <div className=' row py-5 '>
        <div className='col-lg-6 col-12  d-flex align-item-center justify-content-center'>
          <img src={servicesImage} alt='services image'  className='img-fluid'/>
        </div>
        <div className='col-lg-6 col-12 d-flex flex-column  justify-content-center my-4'>
          <h2 className='services-title text-primary'>
            Our Services
          </h2>
          <p className='services-paragraph my-4'>
          Welcome to <span className='text-primary'>Merez Global Logistics</span> —your partner in efficient and reliable logistics solutions. With a global network and advanced technology, we specialize in optimizing your supply chain from transportation to warehousing. Our expert team is dedicated to ensuring your goods are managed and delivered seamlessly.
          </p>
        </div>
      </div>
      </div>
    </section>


    <section className='container py-5'>
      <div className='row'>
        <h3 className='warehouse-title text-primary text-center py-4'>
        Warehousing & Distribution
        </h3>

        <div className='col-lg-6 col-12 d-flex  justify-content-center flex-column'>
          <h4 className='warehouse-subtitle py-4'>
          Flexible, <span className='text-primary'>secure storage</span> and efficient distribution.

          </h4>

          <div className='warehouse-paragraph'>
          <p><span className='text-primary'>Warehousing:</span> Safe, climate-controlled storage for your goods.</p>
         <p> <span className='text-primary'>Order Fulfillment:</span> Fast and accurate picking, packing, and shipping.</p>
          <p><span className='text-primary'>Inventory Management:</span> Real-time tracking of your stock levels.</p>
          </div>
        </div>

        <div className='col-lg-6 col-12 d-flex align-item-center justify-content-center'>
          <img src={WarehouseImage} alt='services image'  className='img-fluid'/>
        </div>
      </div>
    </section>


<section className='bg-light'>
    <div className='container py-5'>
      <div className='row'>
        <h3 className='warehouse-title  text-center py-4'>
        <span class="text-primary"></span>E-Commerce & Last-Mile Delivery
        </h3>

        <div className='col-lg-6 col-12  d-flex align-item-center justify-content-center'>
          <img src={EcommerceImage} alt='services image' className='img-fluid'/>
        </div>

        <div className='col-lg-6 col-12 d-flex  justify-content-center flex-column'>
          

          <div className='warehouse-paragraph'>
          <p>Optimized solutions for online retailers and direct-to-consumer deliveries.</p>
         <p> Last-Mile Delivery: Fast, reliable deliveries right to your customers’ doors.</p>
          <p>Returns Management: Efficient handling of returns to ensure customer satisfaction.</p>
          </div>
        </div>

       
      </div>
    </div>
    </section>

    <CounterSection/>
    <LowerShippingSection/>

    <DownloadApp/>
    </>
  )
}

export default Services