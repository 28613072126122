import React from 'react'
import Logo from '../assets/logo-white.png'
import MenuLinks from './MenuLinks'
import { NavLink } from 'react-router-dom'

const OffCanvasMenu = () => {


  return (
    <div >
  <div className="offcanvas-header">
    <h1 className="offcanvas-title">
        <NavLink to='/'><img src={Logo} className='logo' /></NavLink>
    </h1>
    <button type="button" className="btn-close text-light" data-bs-dismiss="offcanvas" id='menu-close'></button>
  </div>
  <div className="offcanvas-body">
    <MenuLinks/>
    <button className="btn btn-secondary my-4" type="button">Login</button>
  </div>
</div>
  )
}

export default OffCanvasMenu