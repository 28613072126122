import React from 'react'
import {useState, createContext, useContext} from 'react'
//utlities
import UserContext from "../Utilities/UserContext"; 
import Hero from '../sections/Hero';
import TopStores from '../sections/TopStores';
import AnythingDelivered from '../sections/AnythingDelivered';
import TopCities from '../sections/TopCities';
import DownloadApp from '../sections/DownloadApp';

const Home = () => {
    const user = useContext(UserContext)
  return (
    <>
    {/* <div>Home</div> */}
    {/* <p>
        {`Hello ${user}`}
    </p> */}

    <Hero/>
    <TopStores/>
    <AnythingDelivered/>
    <TopCities/>
    <DownloadApp/>
    </>
  )
}

export default Home