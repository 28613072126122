import React from 'react'
import Logo from '../assets/merez-logo.png'
import { BiUser } from "react-icons/bi";
import { NavLink } from 'react-router-dom';
import { CgMenuRight } from "react-icons/cg";
import OffCanvasMenu from './OffCanvasMenu';
import MenuLinks from './MenuLinks';
import { useState, useEffect } from 'react';


const Header = () => {
    const handleClose = () => {
        document.getElementById('menu-close').click()
    }
  return (
    <>
    <header className="wrapper py-4 px-5 d-flex justify-content-between bg-primary header">
        <NavLink to={'/'} className='logo-div' onClick={handleClose}>
            <img src={Logo} className='logo' />
        </NavLink>

        <div className='navbar d-flex justify-content-between d-none d-lg-flex'>
            
            <MenuLinks/>
            
        </div>

        <span className='login'>
            <NavLink to='#' className='btn btn-secondary'>
               <BiUser/> Login
            </NavLink>

            <span className='ml-4 d-inline-block px-3 text-white d-lg-none' data-bs-toggle="offcanvas" data-bs-target="#off-canva-menu">
            <CgMenuRight />
            </span>
        </span>


        
    </header>
    <div  className="offcanvas offcanvas-start bg-primary" id="off-canva-menu">
    <OffCanvasMenu />
    </div>
    </>
  )
}

export default Header