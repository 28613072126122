import React from 'react'
import Image from '../assets/Merez-app.png'

function DownloadApp() {
  return (
    <>
    <div className="download-app-section d-none d-lg-block">
        <div className="row">
        <div className="col-md-6 col-12 col-xs-12">
               

        </div>
            <div className="col-md-6 col-12 col-xs-12 d-flex justify-content-center flex-column download-side">
                <h2 className='download-title'> Download Our App</h2>
                <p className='download-paragragh'>
                    Order anything and track it in real time with the Merez Global Logistics App
                </p>
                <p>
                    <img src={Image} />
                </p>

            </div>
        </div>
       
    </div>

    <div className="download-app-section d-lg-none d-block">
        <div className="row">
        <div className="col-md-6 col-12 col-xs-12">
        <h2 className='download-title-mobile'> Download Our App</h2>
                <p className='download-paragraph-mobile'>
                    Order anything and track it in real time with the Merez Global Logistics App
                </p>
                <p>
                    <img src={Image} />
                </p>

        </div>
            <div className="col-md-6 col-12 col-xs-12 d-flex justify-content-center flex-column">
               
               

            </div>
        </div>
       
    </div>
    <p align="center" className='p-3 text-white bg-primary'>
            Copyright 2024. All rights reserved
        </p>
    </>
  )
}

export default DownloadApp