import React from 'react'
import DownloadApp from '../sections/DownloadApp'
import CounterSection from '../components/CounterSection'
import LowerShippingSection from '../sections/LowerShippingSection'

const About = () => {
  return (
    <>
    <section className="secondary-hero about-hero">
       <h1 className='secondary-hero-title'> We <span className='text-secondary'> move the world</span>. So you don't have to</h1>
       <p className='secondary-hero-paragraph'>
       We are Out Delivering the Future, One Parcel at a Time.
       </p>
    </section>

    <section className='about-section'>
      <h2 className='about-section-title text-center'>Simplifying Your <span class="text-primary">Logistics Challenges</span></h2>
      <p className='about-section-paragraph'>
      At Merez Global Logistics, we specialize in transforming complex logistics into seamless operations. Our expert team handles every detail of your supply chain, from planning to execution, ensuring efficiency and reliability. With our innovative solutions and dedicated support, you can focus on growing your business while we take care of the logistics.
      </p>
    </section>
    <CounterSection/>
    <LowerShippingSection/>
    <DownloadApp/>
    </>
  )
}

export default About