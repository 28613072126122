import React from 'react'
import { NavLink } from 'react-router-dom'

const MenuLinks = () => {
    
    const handleClose = () => {
        document.getElementById('menu-close').click()
    }
  return (
    <>
            <NavLink to={'/about'} className='nav-link text-white me-5 my-2' onClick={handleClose}>
                About us
            </NavLink>
            <NavLink to={'/contact'} className='nav-link text-white me-5 my-2' onClick={handleClose}>
                Contact us
            </NavLink>
            <NavLink to={'/services'} className='nav-link text-white me-5 my-2' onClick={handleClose}>
                Services
            </NavLink>
            <NavLink to={'/packages'} className='nav-link text-white me-5 my-2' onClick={handleClose}>
                Packages
            </NavLink>
    </>
  )
}

export default MenuLinks