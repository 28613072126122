import React from 'react'
import image from '../assets/lower-shipping-image.png'

const LowerShippingSection = () => {
  return (
    <section className="py-5">
        <div className="container">
            <div class="row">
                <div class="col-md-6 col-sm-12  d-flex align-item-center justify-content-center">
                    <img src={image} alt='lower shipping image' className='img-fluid'/>
                </div>

                <div class="col-md-6 col-sm-12 lower-shipping-texts">
                    <p>
                    Enjoy lower shipping costs and handling expenses through our optimized logistics solutions.
</p>
<p>
Benefit from real-time tracking and management systems for enhanced visibility and efficiency.
</p>
<p>
Streamlined processes ensure timely, reliable deliveries and reduce operational delays.
</p>
<p>
Seamlessly manage shipments across international borders with our extensive network.
                    </p>
                </div>
            </div>
        </div>
    </section>
  )
}

export default LowerShippingSection