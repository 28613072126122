import React from 'react'
import NotFoundImage from '../assets/404.jpg'
import DownloadApp from '../sections/DownloadApp'
import { NavLink } from 'react-router-dom'

const NotFound = () => {
  return (
    <div className=''>
      <div className='d-flex justify-content-center align-items-center bg-secondary p-5 flex-column'>
      <img src={NotFoundImage} className='not-found-image my-5'/>
      <NavLink to='/' className='btn btn-primary'>Go to Home</NavLink>
    </div>
      <DownloadApp/>
    </div>
  )
}

export default NotFound