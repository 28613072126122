import React from 'react'

const CounterSection = () => {
  return (
    <section className='bg-primary'>
        <div className="container py-4">
            <div className='row'>
                <div className='col-lg-3 col-6 text-white text-center my-4'>
                    <p>
                        <b>
                            Shipments Delivered
                        </b>

                    </p>
                    <span className='counter-number'>
                        400+
                    </span>
                </div>

                <div className='col-lg-3 col-6 text-white text-center my-4'>
                    <p>
                        <b>
                            Time Saved
                        </b>

                    </p>
                    <span className='counter-number'>
                        30%
                    </span>
                </div>

                <div className='col-lg-3 col-6 text-white text-center my-4'>
                    <p>
                        <b>
                            Order Accuracy
                        </b>

                    </p>
                    <span className='counter-number'>
                        99%
                    </span>
                </div>


                <div className='col-lg-3 col-6 text-white text-center my-4'>
                    <p>
                        <b>
                            Cost Savings
                        </b>

                    </p>
                    <span className='counter-number'>
                        20%
                    </span>
                </div>
            </div>
        </div>
    </section>
  )
}

export default CounterSection