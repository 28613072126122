import React from 'react'

const TopRestaurants = ({image, name}) => {
  return (
    <div className='top-restaurant d-flex flex-column justify-content-center p-2 col-lg-3 col-6 mx-auto'>
        <div className="top-restaurant-image-container mx-auto border-2">
            <img src={image} className='top-restaurant-image'/>
        </div>
        <span className='text-center top-restaurant-text'>
            {name}
        </span>
    </div>
  )
}

export default TopRestaurants