import React from 'react'
import TopRestaurants from '../components/TopRestaurants'
import Chicken from '../assets/chicken.png'

const TopStores = () => {
  return (
    <section className='top-stores container-fluid py-5'>
        <div className='d-flex justify-content-center'>
        <h2 className='text-primary bg-light top-stores-heading'>
            Top stores in Owerri
        </h2>
        </div>

        <div className='row'>
        <TopRestaurants image={Chicken} name={'Chicken Republic'} />
        <TopRestaurants image={Chicken} name={'Chicken Republic'} />
        <TopRestaurants image={Chicken} name={'Chicken Republic'} />
        <TopRestaurants image={Chicken} name={'Chicken Republic'} />
        <TopRestaurants image={Chicken} name={'Chicken Republic'} />
        <TopRestaurants image={Chicken} name={'Chicken Republic'} />
        <TopRestaurants image={Chicken} name={'Chicken Republic'} />
        <TopRestaurants image={Chicken} name={'Chicken Republic'} />
        </div>
       
        
    </section>
  )
}

export default TopStores