import React from 'react'
import { useState } from 'react'
import CounterSection from '../components/CounterSection'
import LowerShippingSection from '../sections/LowerShippingSection'
import DownloadApp from '../sections/DownloadApp'
import { IoMailUnread } from "react-icons/io5";
import { FaClock } from "react-icons/fa";
import Swal from 'sweetalert2'

const Contact = () => {

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")


  const handleSubmit = (e) => {
      e.preventDefault()
      const data = {
        name,
        email,
        message
      }

      Swal.fire("Great", "We have received your message, you will hear from us soon","success")
  }
  return (
    <>
      <section className='contact-section bg-light py-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-7 col-12'>
              <h4 className='text-primary mb-4'>Contact us</h4>
              <h3>
              We’re here for you
              </h3>
              <p className='text-muted mb-4 contact-paragraph'>
              Have a question or feedback? Fill out the form below.
              And we’ll get back to you as soon as possible.
              </p>



              <form method='post'>
                <div className="form-floating my-4">
                  <input type='text' className='form-control' name='name' id='name' onChange={(e)=> setName(e.target.value)} value={name} placeholder='Name'/>
                  <label className='form-label'>Name</label>
                </div>

                <div className="form-floating my-4">
                  <input type='email' className='form-control' name='email' id='email' onChange={(e)=> setEmail(e.target.value)} value={email} placeholder='Email address'/>
                  <label className='form-label'>Email address</label>
                </div>

                <div className="form-floating my-4">
                <textarea class="form-control" id="message" name="message" placeholder="Message" value={message} onChange={e=>{setMessage(e.target.value)}}></textarea>
                <label for="comment">Message</label>
                </div>

                <div className='form-group my-4'>
                  <button className='btn btn-primary' onClick={handleSubmit} >
                    Submit
                  </button>
                </div>


              </form>
            </div>


            <div className='col-12 col-lg-5 d-flex justify-content-center flex-column'>
                <div className='contact-email-section d-flex'>
                  <div className='m-2 contact-icon d-flex justify-content-center'>
                  <IoMailUnread />
                  </div>

                 
                
                <div className='m-2'>
                    <h4>
                      Email us
                    </h4>
                    <p>
                      hello@merezglobal.com
                    </p>
                </div>
                </div>



                <div className='contact-hours-section d-flex'>
                <div className='m-2 contact-icon d-flex justify-content-center'>
                <FaClock />
                  </div>

                 
                
                <div className='m-2'>
                    <h4>
                    Hours of operation
                    </h4>
                    <p>
                    Monday - Friday: 8am - 5pm <br/>
                    Saturday - Sunday: 12pm - 5pm
                    </p>
                </div>
                </div>


            </div>
          </div>
        </div>
      </section>

      <CounterSection/>
      <LowerShippingSection/>
      <DownloadApp/>
    </>
  )
}

export default Contact