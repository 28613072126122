import React from 'react'
import { parsePath } from 'react-router'


const AnythingDeliveredBoxes = ({image, title, paragraph}) => {
  return (
    <div className='col-lg-4 col-12 p-3 h-100'>
        <div className='anything-delivered-box  d-flex justify-content-center align-items-center flex-column p-5'>
        <img src={image} alt={title} />
        <h4>
            {title}
        </h4>
    </div>
    <p className='text-center delivered-paragraph'>
        {paragraph}
    </p>
    </div>
  )
}

export default AnythingDeliveredBoxes