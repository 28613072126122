import React from 'react'
import TopStores from '../assets/store-variety.png' 
import FastDelivery from '../assets/fast-delivery.png'
import LiveTracking from '../assets/live-tracking.png'
import AnythingDeliveredBoxes from '../components/AnythingDeliveredBoxes'

const AnythingDelivered = () => {
  return (
    <section className='container'>
       
        <h2 className='text-center mt-4'>
            Anything delivered
        </h2>
        <div className="row py-4 mx-auto">
        <AnythingDeliveredBoxes image={TopStores} title={"Your city's top stores"} paragraph={'With a great variety of stores you can other your favorite item or explore new stores nearby!'}/>
        <AnythingDeliveredBoxes image={FastDelivery} title={"Fast Delivery"} paragraph={'Like a flash! Order or send anything in your city and receive it in minutes.'} />
        <AnythingDeliveredBoxes image={LiveTracking} title={"Live Tracking"} paragraph={"Monitor your shipments in realtime, knowing exactly  where your goods are and when they’ll arrive."}/>
        </div>


<div className='my-5 py-5 d-flex justify-content-center'>
<button className='btn btn-primary'>
            Explore stores around you
        </button>
</div>
       
    </section>
  )
}

export default AnythingDelivered